import React, { Fragment } from 'react';
import { Link, graphql } from 'gatsby';
import styled from 'styled-components';

import SEO from '../components/SEO';
import Title from '../components/Title';
import Subscribe from '../components/Subscribe';
import media from '../utils/breakpoints';

const Layout = styled.div`
  background-image: url(/footer.svg);
  background-position: left bottom;
  min-height: 100vh;
  padding: 20px;
  ${media.mobile`
    padding: 70px;
  `}
  background-repeat: no-repeat;
  background-size: contain;
  h1 {
    margin: 0;
  }
  p {
    font-size: 1.4rem;
    b {
      font-size: 1.2rem;
    }
  }
  svg {
    width: 50px;
    height: 60px;
  }
`;

const StyledContainer = styled.div`
  max-width: 600px;
  width: 100%;
`;

class LandingPage extends React.Component {
  render() {
    return (
      <Fragment>
        <SEO />
        <Layout>
          <StyledContainer>
            <Title><Link to="/weekly-email/">Health Farm</Link></Title>
            <p>Health Farm is your weekly green smoothie of health and wellness news. Just the good stuff delivered weekly to your email inbox.</p>
            <p><b>Sign up for your weekly shot of news</b></p>
            <Subscribe redirect="/weekly-email" />
          </StyledContainer>
        </Layout>
      </Fragment>
    )
  }
}

export default LandingPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          fields {
            slug
          }
          timeToRead
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            spoiler
            image
          }
        }
      }
    }
  }
`
